body {
  font-family: Arial, Helvetica, sans-serif;
}
.color-picker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
}
h1 {
  margin: 1rem 0;
  font-size: 3rem;
  color: #444;
  transition: color 0.25s ease;
}

.color-display {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 5px solid hsl(0, 0%, 80%);
  border-top: none;
  border-radius: 0 0 2rem 2rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.25s ease, border-color 0.25s ease;
}
.color-display p {
  color: hsl(0, 0%, 20%);
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
  transition: color 0.25s ease;
}
label {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
input[type="color"] {
  width: 80px;
  height: 50px;
  padding: 5px;
  border-radius: 0.5rem;
  border: 2px solid hsl(0, 0%, 80%);
  cursor: pointer;
  transition: border-color 0.25s ease, box-shadow 0.25s ease;
}
input[type="color"]:hover {
  border-color: hsl(0, 0%, 70%);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.chrome-picker {
  background-color: #444 !important;
  border-radius: 1rem !important;
}
.chrome-picker div .flexbox-fix:nth-child(2) {
  display: none !important;
}
.copy-boxes {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: start;
}

.copy-box-container {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.copy-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: hsl(0, 0%, 20%);
  padding: 0.75rem 1rem;
  border-radius: 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.25s ease, box-shadow 0.25s ease;
}
.copy-box-container label {
  margin: 0;
}
.copy-box:hover {
  background-color: hsl(0, 0%, 30%);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.copy-icon {
  margin-right: 0.25rem;
}
.copy-box p {
  margin: 0;
}

.navbar {
  background-color: rgba(0, 0, 0, 0.6);
  border-bottom: 2px solid hsl(0, 0%, 80%);
  border-top: 2px solid hsl(0, 0%, 80%);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}
